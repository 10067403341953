import { Injectable, Inject } from '@angular/core';

import { AppConfig } from '../../../app-config.module';

@Injectable()
export class ConfigService {  
    baseUrl: string; 

    constructor(@Inject('APP_CONFIG') public config: AppConfig) {
      this.baseUrl = this.getBaseUrl();
    }

    isInternetExplorer(): boolean {
        var ua = window.navigator.userAgent;
        var msie = ua.indexOf('MSIE ');
        var trident = ua.indexOf('Trident/');
        var edge = ua.indexOf('Edge/');

        return msie > 0 || trident > 0 || edge > 0;
    }

    private getBaseUrl() {
      return document.getElementsByTagName('base')[0].href;
    }
}
