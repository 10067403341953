import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'column',
  templateUrl: './column.component.html'
})
export class ColumnComponent implements OnInit {
  @Input() type: string;
  typeClass: string;

  constructor() {
  }

  ngOnInit() {
    this.typeClass = this.setTypeClass(this.type);
  }

  private setTypeClass(type: string) {
    switch (type) {
      case "left":
        return "O-ComplexForm__leftColumn";
      case "right":
        return "O-ComplexForm__rightColumn";
      default:
        return "";
    }
  }
}
