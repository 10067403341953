import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'star-ratings',
  templateUrl: './star-ratings.component.html',
  styleUrls: ['./star-ratings.component.scss'],
})
export class StarRatingsComponent {
  selectedValue = null;
  @Input() value: string;
  @Output() ratingClicked: EventEmitter<string> = new EventEmitter<string>();

  ngOnChanges(changes) {
    this.modified()
  }

  public modified(){
    if (this.value == "true") {
      this.selectedValue = null;
    }
}

  constructor() {
  }

  sendMessages(value) {
    this.ratingClicked.emit(value);
  }
 }
