import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
    private isLoading: boolean;
    private broadcast: BehaviorSubject<boolean>;

    constructor() {
        this.isLoading = false;
        this.broadcast = new BehaviorSubject(this.isLoading);
    }

    get(): Observable<boolean> {
        return this.broadcast.asObservable();
    }

    set(isLoading: boolean) {
        this.isLoading = isLoading;
        this.broadcast.next(this.isLoading);
    }
}
