import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import {
  Event,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router
} from "@angular/router";
import { LanguageItem } from "@inst-iot/bosch-angular-ui-components";
import { TranslateService } from "@ngx-translate/core";
import { Subscription } from "rxjs";
import { environment } from "../../environments/environment";
import { CommonModalService } from "../shared/components/common-modal/common-modal.service";
import { LoaderService } from "../shared/components/loader/loader.service";
import { Tile } from "../shared/entities/tile";
import { UserInfo } from "../shared/entities/user-info";
import { ConfigService } from "../shared/services/config/config.service";
import { TileService } from "../shared/services/portal/tile.service";
import { RouteService } from "../shared/services/route/route.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html"
})
export class AppComponent implements OnInit, OnDestroy {
  @ViewChild("modal", { static: true }) modal;
  isLoading: boolean;
  anchorName: string;
  isProduction: boolean;

  subBrand: string;
  languages: LanguageItem[] = [
    { id: "en", name: "English" },
    { id: "es", name: "Español" }
  ];
  language = "en";
  userInfo: UserInfo;
  userInfoSubscription: Subscription;
  loadingSubscription: Subscription;
  tileSubscription: Subscription;
  tile: Tile;
  tileIndexes: number[];
  suggestionsUrl: string;

  constructor(
    private router: Router,
    private configService: ConfigService,
    private loaderService: LoaderService,
    private commonModalService: CommonModalService,
    private routeService: RouteService,
    private tileService: TileService,
    private translateService: TranslateService
  ) {
    this.isLoading = false;
    this.isProduction = environment.isProduction;
    this.anchorName = routeService.getUrl();
    this.subBrand = environment.departmentName;
    this.tile = null;
    this.tileIndexes = [-1, -1];
  }

  ngOnInit() {
    this.commonModalService.setModal(this.modal);
    //if (this.configService.isInternetExplorer()) {
    //  this.commonModalService.warning(
    //    this.commonModalService.messages.common.notSupportedBrowserTitle,
    //    [this.commonModalService.messages.common.notSupportedBrowserMessage]
    //  );
    //}
    this.loadingSubscription = this.loaderService
      .get()
      .subscribe(isLoading => (this.isLoading = isLoading));
    this.router.events.subscribe((routerEvent: Event) => {
      this.checkRouterEvent(routerEvent);
    });
    this.setInitialLanguage();
  }

  ngOnDestroy() {
    this.userInfoSubscription.unsubscribe();
    this.loadingSubscription.unsubscribe();
    this.tileSubscription.unsubscribe();
  }

  setInitialLanguage() {
    const url = this.routeService.getUrl();
    if (url.includes("/es")) {
      this.language = "es";
    } else {
      this.language = "en";
    }
    this.translateService.setDefaultLang(this.language);
    this.translateService.use(this.language);
  }

  setTileIndex(position: number, index: number) {
    this.tileIndexes[position] = index;
    if (
      position === 1 &&
      this.tile.tiles[this.tileIndexes[0]].tiles[index].tiles !== null
    ) {
      this.tileService.set(
        this.tile.tiles[this.tileIndexes[0]].tiles[index].tiles
      );
      this.router.navigate(["portal"]);
    }
  }

  languageChange(event: string) {
    this.translateService.use(event);
  }

  private checkRouterEvent(routerEvent: Event): void {
    if (routerEvent instanceof NavigationStart) {
      this.isLoading = true;
    }
    if (
      routerEvent instanceof NavigationEnd ||
      routerEvent instanceof NavigationCancel ||
      routerEvent instanceof NavigationError
    ) {
      this.isLoading = false;
      this.anchorName = this.routeService.getUrl();
    }
  }
}
