import { Injectable, Inject } from '@angular/core';

import { AppMessages } from '../../../app-messages.module';

@Injectable({
  providedIn: 'root'
})
export class CommonModalService {
    private modal: any;

    constructor(@Inject('APP_MESSAGES') public messages: AppMessages) {
    }

    setModal(modal: any) {
        this.modal = modal;
    }

    success(title: string, contentLines: string[]) {
        this.modal.success(title, contentLines);
    }

    warning(title: string, contentLines: string[]) {
        this.modal.warning(title, contentLines);
    }

    error(title: string, contentLines: string[]) {
        this.modal.error(title, contentLines);
    }
}
