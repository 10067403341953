import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'jumbotron',
  templateUrl: './jumbotron.component.html'
})
export class JumbotronComponent implements OnInit {
  @Input() title: string;
  @Input() color: string;
  colorClass: string;
  
  constructor() {
  }

  ngOnInit() {
    this.colorClass = this.setColorClass(this.color);
  }

  private setColorClass(color: string) {
    switch (color) {
      case "darkBlue":
        return "u-Background__gradient--darkBlue-lightBlue";
      case "fuchsia":
        return "u-Background__gradient--fuchsia-violet";
      case "lightBlue":
        return "u-Background__gradient--lightBlue-turquoise";
      case "lightGreen":
        return "u-Background__gradient--lightGreen-darkGreen";
      case "red":
        return "u-Background__gradient--red-fuchsia";
      case "turquoise":
        return "u-Background__gradient--turquoise-lightGreen";
      case "violet":
        return "u-Background__gradient--violet-darkBlue";
      default:
        return "";
    }
  }
}
