import { Component, Input } from '@angular/core';

import { RouteService } from '../../services/route/route.service';

@Component({
    selector: 'back-to-top',
    templateUrl: './back-to-top.component.html'
})
export class BackToTopComponent {
  @Input() anchorId: string;
  @Input() anchorName: string;

  constructor(private routeService: RouteService) {

  }

  backToTop() {
    this.routeService.scroll(this.anchorId);
  }
}
