import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Observable, of } from 'rxjs';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { mergeMap } from 'rxjs/operators';
import { HttpService } from '../../../shared/services/http/http.service';

@Component({
  selector: 'user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss'],
})

export class UserComponent {

  subBrand: string;
  asyncSelected: string;
  typeaheadLoading: boolean;
  noResult = false;
  dataSource: Observable<any>;

  @Input() optionsLimit;
  @Input() headWaitMs;
  @Input() headMinLength;
  @Output() selectedUser: EventEmitter<any> = new EventEmitter<any>();

  constructor(private httpService: HttpService) {
    this.subBrand = environment.departmentName;

    this.dataSource = Observable.create((observer: any) => {
      observer.next(this.asyncSelected);
    })
      .pipe(
        mergeMap((token: string) => this.getUsers(token))
      );
  }

  getUsers(token: string): Observable<any> {
    if (token) {
      return this.httpService.get("http://hdasrv010.br.bosch.com/HelperDev/api/consult/usersbyquery/" + token);
    }
    return of([]);
  }

  changeTypeaheadLoading(e: boolean): void {
    this.typeaheadLoading = e;
  }

  typeaheadOnSelect(e: TypeaheadMatch): void {
    this.selectedUser.emit(e.item);
  }
}
