import { Component, ViewChild } from "@angular/core";
import { ModalService } from "@inst-iot/bosch-angular-ui-components";

@Component({
  selector: "common-modal",
  templateUrl: "./common-modal.component.html"
})
export class CommonModalComponent {
  title: string;
  type: string;
  contentLines: string[];
  @ViewChild("modal", { static: false }) modal;

  constructor(private modalService: ModalService) {
    this.title = "";
    this.type = "";
    this.contentLines = [];
  }

  public success(title: string, contentLines: string[]): void {
    this.type = "success";
    this.setup(title, contentLines);
    this.modalService.open(this.modal).then(result => {});
  }

  public warning(title: string, contentLines: string[]): void {
    this.type = "warning";
    this.setup(title, contentLines);
    this.modalService.open(this.modal).then(result => {});
  }

  public error(title: string, contentLines: string[]): void {
    this.type = "error";
    this.setup(title, contentLines);
    this.modalService.open(this.modal).then(result => {});
  }

  public setup(title: string, contentLines: string[]): void {
    this.title = title;
    this.contentLines = contentLines;
    this.modalService.open(this.modal).then(result => {});
  }
}
