import { Component, Input, OnInit } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { RouteService } from "../../../shared/services/route/route.service";
import { Link } from "../../entities/link";

@Component({
  selector: "card",
  templateUrl: "./card.component.html",
  styleUrls: ["./card.component.scss"],
})
export class CardComponent implements OnInit {
  anchorName: string;

  @Input() link: Link;
  @Input() color: string;

  colorClass: string;

  constructor(
    private routeService: RouteService,
    private sanitizer: DomSanitizer
  ) {
    this.anchorName = this.routeService.getUrl();
  }

  ngOnInit() {
    this.colorClass = this.setColorClass(this.color);
  }

  sanitize(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  private setColorClass(color: string) {
    switch (color) {
      case "darkBlue":
        return "A-Teaser-StrategicTeaser__backgroundGradient--darkBlue-lightBlue";
      case "fuchsia":
        return "A-Teaser-StrategicTeaser__backgroundGradient--fuchsia-violet";
      case "lightBlue":
        return "A-Teaser-StrategicTeaser__backgroundGradient--lightBlue-turquoise";
      case "lightGreen":
        return "A-Teaser-StrategicTeaser__backgroundGradient--lightGreen-darkGreen";
      case "red":
        return "A-Teaser-StrategicTeaser__backgroundGradient--red-fuchsia";
      case "turquoise":
        return "A-Teaser-StrategicTeaser__backgroundGradient--turquoise-lightGreen";
      case "violet":
        return "A-Teaser-StrategicTeaser__backgroundGradient--violet-darkBlue";
      default:
        return "";
    }
  }
}
