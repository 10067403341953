import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import {
  BreadcrumbsModule,
  ModalModule,
  RbUiComponentsModule,
} from "@inst-iot/bosch-angular-ui-components";
import { TranslateModule } from "@ngx-translate/core";
import { CarouselModule } from "ngx-bootstrap/carousel";
import { TypeaheadModule } from "ngx-bootstrap/typeahead";
import { HttpService } from "./services/http/http.service";

import { ToastrModule } from "ngx-toastr";
import { AppConfigModule } from "../app-config.module";
import { AppMessagesModule } from "../app-messages.module";
import { BackToTopComponent } from "./components/back-to-top/back-to-top.component";
import { CardListComponent } from "./components/card/card-list.component";
import { CardComponent } from "./components/card/card.component";
import { CommonModalComponent } from "./components/common-modal/common-modal.component";
import { JumbotronComponent } from "./components/jumbotron/jumbotron.component";
import { LoaderComponent } from "./components/loader/loader.component";
import { StarRatingsComponent } from "./components/star-ratings/star-ratings.component";
import { UserComponent } from "./components/user/user.component";
import { ConfigService } from "./services/config/config.service";
import { ErrorService } from "./services/error/error.service";
import { FileService } from "./services/file/file.service";
import { NotificationService } from "./services/notification/notification.service";
import { TileService } from "./services/portal/tile.service";
import { RouteService } from "./services/route/route.service";
import { StorageService } from "./services/storage/storage.service";

import { ColumnComponent } from "./components/column/column.component";
import { MainTitleComponent } from "./components/main-title/main-title.component";
import { AppsResolver } from "./resolvers/apps/apps-resolver.service";
import { UserInfoResolver } from "./resolvers/user/user-info-resolver.service";

@NgModule({
  declarations: [
    LoaderComponent,
    CommonModalComponent,
    CardListComponent,
    CardComponent,
    BackToTopComponent,
    JumbotronComponent,
    UserComponent,
    StarRatingsComponent,
    ColumnComponent,
    MainTitleComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    RbUiComponentsModule,
    ModalModule.forRoot(),
    BreadcrumbsModule.forRoot(),
    ToastrModule.forRoot(),
    CarouselModule.forRoot(),
    TypeaheadModule.forRoot(),
  ],
  exports: [
    CommonModule,
    FormsModule,
    AppConfigModule,
    AppMessagesModule,
    RbUiComponentsModule,
    LoaderComponent,
    CommonModalComponent,
    CardListComponent,
    CardComponent,
    BackToTopComponent,
    JumbotronComponent,
    UserComponent,
    StarRatingsComponent,
    ColumnComponent,
    MainTitleComponent,
    CarouselModule,
    TypeaheadModule,
    TranslateModule,
  ],
  providers: [
    HttpService,
    ConfigService,
    NotificationService,
    RouteService,
    ErrorService,
    StorageService,
    TileService,
    FileService,
    UserInfoResolver,
    AppsResolver,
  ],
})
export class SharedModule {}
