import { Injectable } from '@angular/core';

@Injectable()
export class RouteService {
    constructor() {
    }

    scroll(id: string): void {
        let x = document.querySelector(id);
        if (x)
            x.scrollIntoView();
    }

    getUrl(): string {
        return window.location.href;
    }
}
