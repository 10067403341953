import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ConfigService } from '../../services/config/config.service';
import { HttpService } from '../../services/http/http.service';
import { App } from '../../entities/app';

@Injectable()
export class AppsResolver {

  constructor(private httpService: HttpService, private configService: ConfigService) { }
}
