import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { ErrorComponent } from "./app/error.component";
import { GSAInTouchComponent } from "./app/gsaintouch.component";
import { SelectiveStrategy } from "./selective-strategy.service";

@NgModule({
  imports: [
    RouterModule.forRoot(
      [
        {
          path: "home",
          component: GSAInTouchComponent,
          data: { title: "GSA In Touch" },
        },
        {
          path: "es",
          component: GSAInTouchComponent,
          data: { title: "GSA In Touch" },
        },
        {
          path: "en",
          component: GSAInTouchComponent,
          data: { title: "GSA In Touch" },
        },
        {
          path: "notfound",
          component: ErrorComponent,
          data: { title: "404 Not Found" },
        },
        {
          path: "GsaInTouch",
          data: { preload: false },
          loadChildren: () =>
            import("./gsaintouch/gsaintouch.module").then(
              (m) => m.GSAInTouchModule
            ),
        },
        { path: "", redirectTo: "home", pathMatch: "full" },
        { path: "**", redirectTo: "notfound" },
      ],
      { preloadingStrategy: SelectiveStrategy }
    ), //, { enableTracing: true }),
  ],
  providers: [SelectiveStrategy],
  exports: [RouterModule],
})
export class AppRoutingModule {}
