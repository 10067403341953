import { NgModule, InjectionToken, Inject } from '@angular/core';

export class AppMessages {
  common = {
    successTitle: "Success!",
    infoTitle: "Information",
    warningTitle: "Warning",
    errorTitle: "Oops!",
    errorMessage: "There was a problem, please try again. If the problem persists, please contact support.",
    notSupportedBrowserTitle: "Not supported browser",
    notSupportedBrowserMessage: "Please use Firefox for a better website experience.",
    ticketNumberMessage: "Ticket number:",
  };
}

const APP_MESSAGES_DI: AppMessages = new AppMessages();

@NgModule({
  providers: [{
    provide: "APP_MESSAGES",
    useValue: APP_MESSAGES_DI
  }]
})
export class AppMessagesModule { }
