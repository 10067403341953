import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { NotificationService } from '../notification/notification.service';
import { LoaderService } from '../../components/loader/loader.service';

@Injectable()
export class ErrorService {
    constructor(private notificationService: NotificationService, private loaderService: LoaderService) {
    }

    notifyError(error: any) {
      if (error.error && error.error instanceof Blob) {
        const reader = new FileReader();
        const notificationService = this.notificationService;
        const loaderService = this.loaderService;
        reader.onload = function () {
          notificationService.showError(notificationService.messages.common.errorTitle, JSON.stringify(reader.result));
          loaderService.set(false);
        }
        const message = reader.readAsText(error.error);
      }
      else {
        this.notificationService.showError(this.notificationService.messages.common.errorTitle, this.notificationService.messages.common.errorMessage);
        this.loaderService.set(false);
      }
    }

    handleErrors(error: any): Observable<any> {
        let errors: string[] = [];
        let msg: string = "";

        msg = "Status: " + error.status;
        msg += " - Status Text: " + error.statusText;
        msg += " - Exception Message: " + JSON.stringify(error)

        errors.push(msg);

        console.error("An error occurred", errors);

        return throwError(error);
    }
}
