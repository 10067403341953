import { Injectable } from '@angular/core';
import { StorageService } from '../storage/storage.service';
import { Tile } from '../../entities/tile';

const tileKey = "tileKey";

@Injectable()
export class TileService {  
    constructor(private storageService: StorageService) {
    }

    get(): Tile[] | null {
      let data = window.sessionStorage.getItem(tileKey);
      if (data)
        return JSON.parse(data) as Tile[];
      return null;
    }

    set(tiles: Tile[]): void {
      window.sessionStorage.setItem(tileKey, JSON.stringify(tiles));
    }
}
