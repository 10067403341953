import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'main-title',
  templateUrl: './main-title.component.html'
})
export class MainTitleComponent implements OnInit {
  @Input() text: string;
  
  constructor() {
  }

  ngOnInit() {
  }
}
