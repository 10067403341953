import { Component, OnInit } from '@angular/core';
import { CarouselConfig } from 'ngx-bootstrap/carousel';

@Component({
  templateUrl: './gsaintouch.component.html',
  styleUrls: ['./gsaintouch.component.scss'],
    providers: [
    { provide: CarouselConfig, useValue: { interval: 4000, noPause: true, showIndicators: true } }
  ]
})
export class GSAInTouchComponent implements OnInit {
  country = [
    { id: 'Canada', label: 'Canadá' },
    { id: 'Estados Unidos de América', label: 'Estados Unidos de América' },
    { id: 'México', label: 'México' }
  ];

  constructor() {
  }

  ngOnInit() {
  }
}
