import { NgModule, InjectionToken, Inject } from '@angular/core';
import { environment } from '../environments/environment.prod';

export class AppConfig {
  logPublishers: LogPublisherConfig[] = [];
  home = {
  };
  }

class LogPublisherConfig {
    name: string = "";
    logLocation: string = "";
    clearLocation: string = "";
    isActive: boolean = false;
}

const APP_CONFIG_DI: AppConfig = new AppConfig();

@NgModule({
    providers: [{
        provide: "APP_CONFIG",
        useValue: APP_CONFIG_DI
    }]
})
export class AppConfigModule { }
